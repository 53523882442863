<template>
  <div class="pricing">
    <Header />
    <div class="mt-20 lg:pl-24 lg:pr-24">
      <section>
        <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-12 lg:mb-16 sm:mb-8">
          <div class="font-semibold text-center lg:mb-8 sm:mb-4">
            <h2 class="sm:text-4xl text-3xl mb-4 text-center">Our Pricing Philosophy</h2>
          </div>
          <div class="lg:mx-auto lg:w-2/3 lg:mb-8 sm:text-justify sm:mb-6">
            <p class="lg:text-center">
              Put quite simply, if we do not feel we are best placed to provide you with
              what you need, we will say so and save you time and money. There is nothing
              to be gained by either party if we take on a commission without the
              necessary time and skills to do a professional job. We do not consider
              ourselves experts at everything but rely on your judgment on the kind of
              service we shall have provided. We believe that our clients and the
              community members are the experts and we endeavor to find that expertise in
              them through our people friendly consultancy tools.
            </p>
          </div>
          <div class="mx-auto max-w-lg text-center">
            <div
              class="text-center border-t-4 border-burgundy mb-6 mx-auto w-1/2 mt-4"
            ></div>
            <h2 class="text-3xl font-bold sm:text-4xl mb-8">Our Pricing Policy</h2>
          </div>
          <div class="mx-auto lg:w-3/4 sm:w-full">
            <p class="lg:text-center mb-4">
              Whilst our standard charge is by the day, we do not charge for time which
              the client does not need. Therefore, if we are only asked for half a day's
              work, you will only pay for half a day.
            </p>
            <p class="lg:text-center mb-4">
              Our normal daily charge for Kenya based clients will be negotiated. This
              rate is the same for off-site preparation and research time as for on-site
              activities. Rates for work overseas will be negotiated on a case by case
              basis. For generally advertised development programmes where a client is
              booking one or more places, there will be a charge per delegate rather than
              a daily fee. Hotel accommodation train and air tickets, and other
              subsistence expenses incurred in respect of the consultant are charged at a
              cost.
            </p>
            <p class="lg:text-center mb-4">
              Training and hotel accommodation booked by MILESTONES Development
              Consultancy on behalf of clients will be invoiced directly to the client by
              the venue concerned or paid by MILESTONES Development Consultancy and
              recharged to the client at cost. Special Order Consumables such as software
              license fees, publications, and psychometric testing materials, are charged
              at cost. VAT will be added to all invoices in respect of eligible items
              Hotel accommodation train and air tickets, and other subsistence expenses
              incurred in respect of the consultant are charged at cost.
            </p>
            <p class="lg:text-center mb-4">
              Training and hotel accommodation booked by Development Consultancy on behalf
              of clients will be invoiced directly to the client by the venue concerned or
              paid by Development Consultancy and recharged to the client at cost Special
              Order Consumables such as software license fees, publications, and
              psychometric testing materials, are charged at cost. VAT will be added to
              all invoices in respect of eligible items
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header2.vue";

export default {
  components: { Header },
};
</script>
