<template>
  <div class="faq">
    <Header />
    <div class="mt-20 lg:pl-24 lg:pr-24">
      <section>
        <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-12 lg:mb-12 sm:mb-8">
          <div class="font-semibold text-center lg:mb-8 sm:mb-4">
            <h2 class="sm:text-4xl text-3xl sm:mb-4">FAQs</h2>
            <div class="space-y-4">
              <details class="group border-l-4 border-burgundy bg-gray-50 p-6">
                <summary class="flex cursor-pointer items-center justify-between">
                  <h2 class="text-lg font-medium text-gray-900">
                    What is Milestones Consultancy?
                  </h2>

                  <span
                    class="ml-1.5 flex-shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 flex-shrink-0 transition duration-300 group-open:-rotate-45"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </summary>

                <p class="mt-4 leading-relaxed text-gray-700">
                  Milestones Development Consultancy is a consultancy firm that provides
                  consultation services with an aim to improve your business. We strive to
                  provide our clients with the best that is out there.
                </p>
              </details>

              <details class="group border-l-4 border-burgundy bg-gray-50 p-6">
                <summary class="flex cursor-pointer items-center justify-between">
                  <h2 class="text-lg font-medium text-gray-900">
                    What services do we offer?
                  </h2>

                  <span
                    class="ml-1.5 flex-shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 flex-shrink-0 transition duration-300 group-open:-rotate-45"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </summary>

                <p class="mt-4 leading-relaxed text-gray-700">
                  Our services are categorized into Strategy, Leadership and Development.
                </p>
              </details>

              <details class="group border-l-4 border-burgundy bg-gray-50 p-6">
                <summary class="flex cursor-pointer items-center justify-between">
                  <h2 class="text-lg font-medium text-gray-900">
                    Where is Milestones Consultancy Located?
                  </h2>

                  <span
                    class="ml-1.5 flex-shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 flex-shrink-0 transition duration-300 group-open:-rotate-45"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </summary>

                <p class="mt-4 leading-relaxed text-gray-700">
                  We are currently located at NGCDF Building, Siaya. 1st Floor, Door No. 1
                </p>
              </details>
              <details class="group border-l-4 border-burgundy bg-gray-50 p-6">
                <summary class="flex cursor-pointer items-center justify-between">
                  <h2 class="text-lg font-medium text-gray-900">
                    What is our pricing policy?
                  </h2>

                  <span
                    class="ml-1.5 flex-shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 flex-shrink-0 transition duration-300 group-open:-rotate-45"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </summary>

                <p class="mt-4 leading-relaxed text-gray-700">
                  Our prices vary depending on various factors. Kindly refer to our
                  pricing page to get more information.
                </p>
              </details>
              <details class="group border-l-4 border-burgundy bg-gray-50 p-6">
                <summary class="flex cursor-pointer items-center justify-between">
                  <h2 class="text-lg font-medium text-gray-900">
                    What is Milestones Consultancy's motto??
                  </h2>

                  <span
                    class="ml-1.5 flex-shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 flex-shrink-0 transition duration-300 group-open:-rotate-45"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </summary>

                <p class="mt-4 leading-relaxed text-gray-700">
                  At Milestones, we believe that together each achieves more.
                </p>
              </details>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header2.vue";
export default {
  components: { Header },
};
</script>
