<template>
  <div class="about">
    <Header />
    <div class="mt-20 lg:pl-24 lg:pr-24">
      <section>
        <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-12 lg:mb-16 sm:mb-8">
          <div class="font-semibold text-center lg:mb-8 sm:mb-4">
            <h2 class="sm:text-4xl text-3xl">Milestones Consultancy</h2>
            <h1 class="italic text-gray-700 text-xl">Together each achieves more</h1>
          </div>
          <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
            <div class="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:h-100">
              <img
                alt="Party"
                src="@/assets/images/image04.jpeg"
                class="transition ease-in absolute inset-0 h-full w-full object-cover scale-100 hover:scale-110 ease-in duration-500 cursor-pointer"
              />
            </div>

            <div class="">
              <div class="text-center border-t-4 border-burgundy mb-6 w-1/4"></div>
              <h2 class="text-2xl font-bold sm:text-4xl lg:text-2xl text-gray-600">
                About Milestones Consultancy
              </h2>

              <p class="mt-4 text-gray-600 lg:text-base">
                MILESTONES Development Consultancy’s main activity is supporting teams,
                departments, communities, professions or whole organizations in
                identifying their priorities, building on those priorities and then
                working together more effectively to address the underlying causes of
                those priority needs.
              </p>
              <p class="mt-2 text-gray-600 lg:text-sm">
                Our reputation is originally founded on our facilitation services, but we
                also provide support to teams in difficulty, advice and support for
                organizational change programmes, consultancy in joint working, and access
                to learning away from the workplace.
              </p>
              <p class="mt-2 text-gray-600 lg:text-sm">
                At MILESTONES, we have a strong commitment to ethical business practices
                and wish to reinforce that commitment by working with clients who respect
                the same values. For this reason we are happy to set out the guarantee and
                philosophy which will guide our business dealings. This page also outlines
                our pricing structure. If you do not feel that we have provided a service
                to the standards expected and agreed, and then you do not have to pay us a
                penny; and you are the sole judge in this matter.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div class="mx-auto max-w-lg text-center">
            <div class="text-center border-t-4 border-burgundy mb-6 m-auto w-1/2"></div>
            <h2 class="text-3xl font-bold sm:text-4xl">Our Vision</h2>

            <p class="mt-4">
              Enlightened communities finding own solutions for own problems using
              resources within their reach.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div class="mx-auto max-w-lg text-center">
            <div class="text-center border-t-4 border-burgundy mb-6 m-auto w-1/2"></div>
            <h2 class="text-3xl font-bold sm:text-4xl">Our Mission</h2>

            <p class="mt-4">
              To be a world class consultancy organization providing strategy, leadership
              and development knowledge using various participatory approaches to meet
              local and international community needs at affordable rates
            </p>
          </div>
        </div>
      </section>
      <section>
        <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div class="mx-auto max-w-lg text-center">
            <div class="text-center border-t-4 border-burgundy mb-6 m-auto w-1/2"></div>
            <h2 class="text-3xl font-bold sm:text-4xl">Our Motto</h2>

            <p class="mt-4">Together Each Achieves More (TEAM)</p>
          </div>
        </div>
      </section>
      <section>
        <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div class="mx-auto max-w-lg text-center">
            <div class="text-center border-t-4 border-burgundy mb-6 m-auto w-1/2"></div>
            <h2 class="text-3xl font-bold sm:text-4xl">Our Belief</h2>

            <p class="mt-4">
              From each according to his ability to each according to his needs
            </p>
          </div>
        </div>
      </section>
      <section id="meet-the-team">
        <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div class="mx-auto max-w-lg text-center">
            <div class="text-center border-t-4 border-burgundy mb-6 m-auto w-1/2"></div>
            <h2 class="text-3xl font-bold sm:text-4xl">Meet Our Amazing Team</h2>

            <p class="mt-4">
              Feel free to reach out to any member of our team for expert advice and personalized solutions. 
              We look forward to partnering with you on your journey to success.
            </p>
          </div>

          <div class="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            <div
              class="group relative block bg-black scale-100 hover:scale-105 ease-in duration-500"
            >
              <img
                alt="President"
                src="@/assets/images/auscar-president.jpg"
                class="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
              />

              <div class="relative p-8">
                <p class="text-sm font-medium uppercase tracking-widest text-black">
                  President
                </p>

                <p class="text-2xl font-bold text-white">Mr. Auscar Wambiya</p>

                <div class="mt-64">
                  <div
                    class="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100"
                  >
                    <p class="text-sm text-white">
                      Having worked with local and international non government
                      organizations for over 10 years including Center for Education,
                      Population, Environment and Development, Care International in
                      Kenya, ChildFund International in Kenya, TEMBEA Youth Centre for
                      Sustainable Development among others, Auscar brings into the firm
                      unrivaled experience in adult development programming, community
                      project design, implementation, monitoring and evaluation.
                    </p>
                    <button
                      class="rounded bg-slate-600 p-3 mt-3 mr-5 text-white font-bold hover:bg-white/50 hover:text-slate-600"
                    >
                      <a
                        :href="`${publicPath}CV - Auscar Wambiya.pdf`"
                        download="Curriculum_Vitae_Auscar_Wambiya"
                      >
                        Download CV</a
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="group relative block bg-black scale-100 hover:scale-105 ease-in duration-500"
            >
              <img
                alt="Lead Consultancy - Strategy"
                src="@/assets/images/jared.jpg"
                class="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
              />

              <div class="relative p-8">
                <p class="text-sm font-medium uppercase tracking-widest text-black">
                  Lead Consultancy - Strategy
                </p>

                <p class="text-2xl font-bold text-white">Mr. Jared Omondi Buoga</p>

                <div class="mt-64">
                  <div
                    class="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100"
                  >
                    <p class="text-sm text-white">
                      Having worked with local and international non government
                      organizations for over 10 years including Center for Education,
                      Population, Environment and Development, Care International in
                      Kenya, ChildFund International in Kenya, TEMBEA Youth Centre for
                      Sustainable Development among others, Auscar brings into the firm
                      unrivaled experience in adult development programming, community
                      project design, implementation, monitoring and evaluation.
                    </p>
                    <button
                      class="rounded bg-slate-600 p-3 mt-3 mr-5 text-white font-bold hover:bg-white/50 hover:text-slate-600"
                    >
                      <a
                        :href="`${publicPath}CV - Jared Buoga.pdf`"
                        download="Curriculum_Vitae_Jared_Buoga"
                      >
                        Download CV</a
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="group relative block bg-black scale-100 hover:scale-105 ease-in duration-500"
            >
              <img
                alt="Lead Consultant – Leadership"
                src="@/assets/images/ruth.jpg"
                class="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
              />

              <div class="relative p-8">
                <p class="text-sm font-medium uppercase tracking-widest text-black">
                  Lead Consultant – Leadership
                </p>

                <p class="text-2xl font-bold text-white">Mrs. Ruth Dero</p>

                <div class="mt-64">
                  <div
                    class="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100"
                  >
                    <p class="text-sm text-white">
                      Ruth Dero is a Senior Level Professional in Communication and Public
                      Relations. She holds a Bachelors of Science (B.Sc) Degree from Moi
                      University with a Second Class Upper Division, a Higher Diploma in
                      Public Relations Management from the University of Nairobi and
                      currently pursuing a Masters Degree in Communication studies in Moi
                      University.
                    </p>
                    <button
                      class="rounded bg-slate-600 p-3 mt-3 mr-5 text-white font-bold hover:bg-white/50 hover:text-slate-600"
                    >
                      <a
                        :href="`${publicPath}CV - Ruth Dero.pdf`"
                        download="Curriculum_Vitae_Ruth_Dero"
                      >
                        Download CV</a
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="group relative block bg-black scale-100 hover:scale-105 ease-in duration-500"
            >
              <img
                alt="Lead Consultancy - Strategy"
                src="@/assets/images/peter.jpeg"
                class="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
              />

              <div class="relative p-8">
                <p class="text-sm font-medium uppercase tracking-widest text-black">
                  Lead Consultant - Development
                </p>

                <p class="text-2xl font-bold text-white">Mr. Peter Aduda</p>

                <div class="mt-64">
                  <div
                    class="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100"
                  >
                    <p class="text-sm text-white">
                      Peter has Master of Arts Degree in Social Policy from Maseno University, a Diploma in Project
                      Management from Kenya Institute of Management and a Bachelor’s Degree of Arts in Sociology
                      & History. Peter has over 15 years’ experience working with civil society, communities, the public
                      and private sectors on issues of social justice and human rights, governance, citizen participation,
                      accountability, community organizing, social research, policy formulation and implementation.
                    </p>
                    <button
                      class="rounded bg-slate-600 p-3 mt-3 mr-5 text-white font-bold hover:bg-white/50 hover:text-slate-600"
                    >
                      <a
                        :href="`${publicPath}CV - Peter Aduda.pdf`"
                        download="Curriculum_Vitae_Peter_Aduda"
                      >
                        Download CV</a
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="group relative block bg-black scale-100 hover:scale-105 ease-in duration-500"
            >
              <img
                alt="Lead Consultant - Development"
                src="@/assets/images/paschal.jpg"
                class="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
              />

              <div class="relative p-8">
                <p class="text-sm font-medium uppercase tracking-widest text-black">
                  Associate Consultant - Education Research
                </p>

                <p class="text-2xl font-bold text-white">Dr. Paschal Achatch</p>

                <div class="mt-64">
                  <div
                    class="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100"
                  >
                    <p class="text-sm text-white">
                      He holds a Ph.D in Education with a specialization in Education
                      Research and Evaluation. At the Curriculum Development Centre in
                      Kenya where he worked for 9 years, he evaluated many education
                      programs including TIVET programs. Dr. Paschal worked for the
                      International Labour Organization’s Program on the Elimination of
                      Child Labour for 9 years as the Education and Training officer.
                      During this time, he oversaw many evaluations in his department.
                    </p>
                    <button
                      class="rounded bg-slate-600 p-3 mt-3 mr-5 text-white font-bold hover:bg-white/50 hover:text-slate-600"
                    >
                      <a
                        :href="`${publicPath}CV - Paschal Wambiya.pdf`"
                        download="Curriculum_Vitae_Paschal_Achatch"
                      >
                        Download CV</a
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="group relative block bg-black scale-100 hover:scale-105 ease-in duration-500"
            >
              <img
                alt="Associate Consultant - Governance"
                src="@/assets/images/eric.jpg"
                class="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
              />

              <div class="relative p-8">
                <p class="text-sm font-medium uppercase tracking-widest text-black">
                  Associate Consultant - Governance
                </p>

                <p class="text-2xl font-bold text-white">Mr. Erick Ogenga</p>

                <div class="mt-64">
                  <div
                    class="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100"
                  >
                    <p class="text-sm text-white">
                      With experience gained as the clerk to the County Assembly of Siaya, 
                      Erick brings to this training a rich background of offering legislative and procedural advice 
                      to Assembly leadership, legislative research, policy development and policy analysis, drafting 
                      of legislative statements, motions, and petitions, analysis of legislative bills, capacity 
                      building of institutions and institutional managers.
                    </p>
                    <button
                      class="rounded bg-slate-600 p-3 mt-3 mr-5 text-white font-bold hover:bg-white/50 hover:text-slate-600"
                    >
                      <a
                        :href="`${publicPath}CV - Eric Ogenga.pdf`"
                        download="Curriculum_Vitae_Eric_Ogenga"
                      >
                        Download CV</a
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="group relative block bg-black scale-100 hover:scale-105 ease-in duration-500"
            >
              <img
                alt="Lead Consultant - Strategy"
                src="@/assets/images/mark.jpg"
                class="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
              />

              <div class="relative p-8">
                <p class="text-sm font-medium uppercase tracking-widest text-black">
                  Associate Consultant - Governance
                </p>

                <p class="text-2xl font-bold text-white">Mr. Mark Okowa</p>

                <div class="mt-64">
                  <div
                    class="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100"
                  >
                    <p class="text-sm text-white">
                      Mark Okowa is  a governance and  development consultant in Kenya
                      with expertise in the following areas:  Facilitation skills,
                      Fundraising and project development, Baseline surveys, Needs
                      assessment and community mapping, Monitoring and evaluation,
                      Right-based Policy Advocacy, Civil Society capacity building,
                      Livelihood support and HIV/AIDS mitigation ,  Community Engagement
                      and democracy promotion , Peace building and Conflict mitigation.
                    </p>
                    <button
                      class="rounded bg-slate-600 p-3 mt-3 mr-5 text-white font-bold hover:bg-white/50 hover:text-slate-600"
                    >
                      <a
                        :href="`${publicPath}CV - Mark Okowa.pdf`"
                        download="Curriculum_Vitae_Mark_Okowa"
                      >
                        Download CV</a
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="group relative block bg-black scale-100 hover:scale-105 ease-in duration-500"
            >
              <img
                alt="Programs Administrator"
                src="@/assets/images/philip.jpg"
                class="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
              />

              <div class="relative p-8">
                <p class="text-sm font-medium uppercase tracking-widest text-black">
                  Programs Administrator
                </p>

                <p class="text-2xl font-bold text-white">Mr. Phillip Ofafa Otolo</p>

                <div class="mt-64">
                  <div
                    class="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100"
                  >
                    <p class="text-sm text-white">
                      Phillip has a Bachelor of Science in Communication and Media Studies (2017-2021) degree from
                      Rongo University. He currently works as the Programs Administrator at Milestones Consultants
                      where he is involved with scheduling of consultancy assignments, design and development of
                      marketing content, management of mainstream and social media accounts, follow up with clients
                      on needs and assignments, report writing and presentation, documentation of assignments
                      including training events, liaison with partners and stakeholder engagement among any other roles
                      assigned by the Team Leader.
                    </p>
                    <button
                      class="rounded bg-slate-600 p-3 mt-3 mr-5 text-white font-bold hover:bg-white/50 hover:text-slate-600"
                    >
                      <a
                        :href="`${publicPath}CV - Philip Otolo.pdf`"
                        download="Curriculum_Vitae_Philip_Otolo"
                      >
                        Download CV</a
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="group relative block bg-black scale-100 hover:scale-105 ease-in duration-500"
            >
              <img
                alt="Associate Consultant"
                src="@/assets/images/grace.jpg"
                class="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
              />

              <div class="relative p-8">
                <p class="text-sm font-medium uppercase tracking-widest text-black">
                  Associate Consultant
                </p>

                <p class="text-2xl font-bold text-white">Mrs Grace Adhiambo</p>

                <div class="mt-64">
                  <div
                    class="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100"
                  >
                    <p class="text-sm text-white">
                      Grace Adhiambo is a Communication Specialist who has worked with the County
                      Government of Siaya, Directorate of Communication for 7 years. She is the owner of
                      Nyogwato TV a YouTube channel that reports on daily happenings in Siaya County. She
                      is also the brain behind Siaya Daily Updates YouTube channel. She is the Founder of
                      ‘ Duond Mine’ a Community Based Organization that deals with Mental health issues
                      in Siaya and Kisumu Counties.
                    </p>
                    <button
                      class="rounded bg-slate-600 p-3 mt-3 mr-5 text-white font-bold hover:bg-white/50 hover:text-slate-600"
                    >
                      <a
                        :href="`${publicPath}CV - Grace Adhiambo.pdf`"
                        download="Curriculum_Vitae_Grace_Adhiambo"
                      >
                        Download CV</a
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
            <div
              class="group relative block bg-black scale-100 hover:scale-105 ease-in duration-500"
            >
              <img
                alt="Associate Consultant"
                src="@/assets/images/moses.jpg"
                class="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
              />

              <div class="relative p-8">
                <p class="text-sm font-medium uppercase tracking-widest text-black">
                  Associate Consultant
                </p>

                <p class="text-2xl font-bold text-white">Mr. Moses Kidi</p>

                <div class="mt-64">
                  <div
                    class="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100"
                  >
                    <p class="text-sm text-white">
                      Moses is a holder of Bachelor’s Degree in Sociology and MBA in Strategic management from the Catholic University of Eastern Africa, 
                      has over 13 years’ experience in program and event management, professional development and corporate communications. Accomplished 
                      in developing and managing programs and events. Moses has supported various team building sessions and programming for organisations 
                      and institutions and successfully managed change processes by building and leading multi-cultural teams towards the delivery of their objectives. 
                      
                    </p>
                  </div>
                </div>
              </div>
            </div>
            

          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header2.vue";

export default {
  components: { Header },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>
