<template>
  <div class="services">
    <Header />
    <div class="mt-20 lg:pl-24 lg:pr-24">
      <section>
        <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-12 lg:mb-12 sm:mb-8">
          <div class="font-semibold text-center lg:mb-8 sm:mb-4">
            <h2 class="sm:text-4xl text-3xl sm:mb-4">Services Overview</h2>
          </div>
          <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
            <div
              class="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-100"
            >
              <img
                alt="Milestones"
                src="@/assets/images/image05.jpeg"
                class="absolute inset-0 h-full w-full object-cover"
              />
            </div>

            <div class="lg:py-4">
              <p class="mt-4 text-gray-600 mb-4">
                Our company is fully dedicated to providing you with the best service. Our
                aim is to help our customers reach their goals through the individualized
                attention you and your stakeholders deserve.
              </p>
              <p class="mb-4 text-gray-600">Our services are categorised into :</p>
              <ul class="text-burgundy">
                <li class="relative mb-4 pl-6">Strategy</li>
                <li class="relative mb-4 pl-6">Leadership</li>
                <li class="relative mb-4 pl-6">Development</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div class="mx-auto max-w-lg text-center">
            <div class="text-center border-t-4 border-burgundy mb-6 m-auto w-1/2"></div>
            <h2 class="text-3xl font-bold text-4xl">Strategy</h2>
          </div>
        </div>
      </section>
      <section>
        <div class="mx-auto max-w-screen-xl px-4 py-4 sm:px-6 lg:px-12">
          <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
            <div class="swiper-container overflow-hidden">
              <div class="swiper-wrapper cursor-pointer">
                <div
                  class="swiper-slide relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-80"
                >
                  <img
                    alt="Milestones"
                    src="@/assets/images/image01.jpeg"
                    class="absolute inset-0 h-full w-full object-cover"
                  />
                </div>
                <div
                  class="swiper-slide relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-80"
                >
                  <img
                    alt="Milestones"
                    src="@/assets/images/image04.jpeg"
                    class="absolute inset-0 h-full w-full object-cover"
                  />
                </div>

                <div
                  class="swiper-slide relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-80"
                >
                  <img
                    alt="Milestones"
                    src="@/assets/images/image05.jpeg"
                    class="absolute inset-0 h-full w-full object-cover"
                  />
                </div>

                <div
                  class="swiper-slide relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-80"
                >
                  <img
                    alt="Milestones"
                    src="@/assets/images/image03.jpeg"
                    class="absolute inset-0 h-full w-full object-cover"
                  />
                </div>
                <div
                  class="swiper-slide relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-80"
                >
                  <img
                    alt="Milestones"
                    src="@/assets/images/image02.jpeg"
                    class="absolute inset-0 h-full w-full object-cover"
                  />
                </div>
              </div>
              <div class="swiper-pagination !relative !bottom-0 mt-12 text-xl"></div>
            </div>

            <div class="pt-4">
              <ul class="">
                <li class="relative mb-4 pl-6">Development of Strategic Plans</li>
                <li class="relative mb-4 pl-6">
                  Development of Asset Inventory Register
                </li>
                <li class="relative mb-4 pl-6">Training on Organizational Strategy</li>
                <li class="relative mb-4 pl-6">Communication Policy Development</li>
                <li class="relative mb-4 pl-6">Social Media Policy Development</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div class="mx-auto max-w-lg text-center">
            <div class="text-center border-t-4 border-burgundy mb-6 m-auto w-1/2"></div>
            <h2 class="text-3xl font-bold sm:text-4xl">Leadership</h2>
          </div>
        </div>
      </section>
      <section>
        <div class="mx-auto max-w-screen-xl px-4 py-4 sm:px-6 lg:px-12">
          <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
            <div class="pt-4">
              <ul class="">
                <li class="relative mb-4 pl-6">Mentorship for Student Councils</li>
                <li class="relative mb-4 pl-6">Career Options Guidance & Development</li>
                <li class="relative mb-4 pl-6">Motivational Speaking</li>
                <li class="relative mb-4 pl-6">
                  Group Dynamics and Interpersonal Skills for Team Building
                </li>
                <li class="relative mb-4 pl-6">Personal Coaching and Mentoring</li>
                <li class="relative mb-4 pl-6">Change Management</li>
                <li class="relative mb-4 pl-6">Team Building</li>
                <li class="relative mb-4 pl-6">360 Degree Appraisal and Feedback</li>
                <li class="relative mb-4 pl-6">Emotional Intelligence</li>
                <li class="relative mb-4 pl-6">Mental Health</li>
              </ul>
            </div>

            <div class="swiper-container1 overflow-hidden">
              <div class="swiper-wrapper cursor-pointer">
                <div
                  class="swiper-slide relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-80"
                >
                  <img
                    alt="Milestones"
                    src="@/assets/images/image09.jpeg"
                    class="absolute inset-0 h-full w-full object-cover"
                  />
                </div>
                <div
                  class="swiper-slide relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-80"
                >
                  <img
                    alt="Milestones"
                    src="@/assets/images/image10.jpeg"
                    class="absolute inset-0 h-full w-full object-cover"
                  />
                </div>

                <div
                  class="swiper-slide relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-80"
                >
                  <img
                    alt="Milestones"
                    src="@/assets/images/image11.jpeg"
                    class="absolute inset-0 h-full w-full object-cover"
                  />
                </div>

                <div
                  class="swiper-slide relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-80"
                >
                  <img
                    alt="Milestones"
                    src="@/assets/images/image12.jpeg"
                    class="absolute inset-0 h-full w-full object-cover"
                  />
                </div>
              </div>
              <div class="swiper-pagination !relative !bottom-0 mt-12 text-xl"></div>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div class="mx-auto max-w-lg text-center">
            <div class="text-center border-t-4 border-burgundy mb-6 m-auto w-1/2"></div>
            <h2 class="text-3xl font-bold sm:text-4xl">Development</h2>
          </div>
        </div>
      </section>
      <section>
        <div class="mx-auto max-w-screen-xl px-4 py-4 sm:px-6 lg:px-12">
          <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
            <div class="swiper-container2 overflow-hidden">
              <div class="swiper-wrapper cursor-pointer">
                <div
                  class="swiper-slide relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-80"
                >
                  <img
                    alt="Milestones"
                    src="@/assets/images/image07.jpeg"
                    class="absolute inset-0 h-full w-full object-cover"
                  />
                </div>
                <div
                  class="swiper-slide relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-80"
                >
                  <img
                    alt="Milestones"
                    src="@/assets/images/image08.jpeg"
                    class="absolute inset-0 h-full w-full object-cover"
                  />
                </div>
              </div>
              <div class="swiper-pagination !relative !bottom-0 mt-12 text-xl"></div>
            </div>

            <div class="pt-4">
              <ul class="">
                <li class="relative mb-4 pl-6">
                  Participatory Community Development and Leadership Skills
                </li>
                <li class="relative mb-4 pl-6">Conflict Management and Resolution</li>
                <li class="relative mb-4 pl-6">Safe Water Systems</li>
                <li class="relative mb-4 pl-6">Group Savings and Loans</li>
                <li class="relative mb-4 pl-6">Child Rights and Protection</li>
                <li class="relative mb-4 pl-6">Business Proposals Development</li>
                <li class="relative mb-4 pl-6">
                  Socio-Economic Analysis towards Poverty Alleviation
                </li>
                <li class="relative mb-4 pl-6">Human Resource Management</li>
                <li class="relative mb-4 pl-6">Report Writing</li>
                <li class="relative mb-4 pl-6">Participation in Local Governance</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header2.vue";
import { Swiper, Autoplay, Pagination, Navigation, EffectFade } from "swiper";
import "swiper/scss";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
export default {
  components: { Header, Swiper },
  mounted() {
    Swiper.use(Autoplay, Pagination, Navigation, EffectFade);
    const swiper = new Swiper(".swiper-container", {
      direction: "horizontal",
      loop: true,
      speed: 5000,
      effect: "fade",
      modules: [Autoplay, Pagination, Navigation, EffectFade],
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        el: ".swiper-slide",
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      navigation: {
        nextEl: "next-button",
        prevEl: "prev-button",
      },

      on: {
        init() {
          this.el.addEventListener("mouseenter", () => {
            this.autoplay.stop();
          });
          this.el.addEventListener("mouseleave", () => {
            this.autoplay.start();
          });
        },
      },
    });
    swiper.autoplay.start();
    const swiper1 = new Swiper(".swiper-container1", {
      direction: "horizontal",
      loop: true,
      speed: 5000,
      effect: "fade",
      modules: [Autoplay, Pagination, Navigation, EffectFade],
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        el: ".swiper-slide",
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      navigation: {
        nextEl: "next-button",
        prevEl: "prev-button",
      },

      on: {
        init() {
          this.el.addEventListener("mouseenter", () => {
            this.autoplay.stop();
          });
          this.el.addEventListener("mouseleave", () => {
            this.autoplay.start();
          });
        },
      },
    });
    swiper1.autoplay.start();
    const swiper2 = new Swiper(".swiper-container2", {
      direction: "horizontal",
      loop: true,
      speed: 5000,
      effect: "fade",
      modules: [Autoplay, Pagination, Navigation, EffectFade],
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        el: ".swiper-slide",
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      navigation: {
        nextEl: "next-button",
        prevEl: "prev-button",
      },

      on: {
        init() {
          this.el.addEventListener("mouseenter", () => {
            this.autoplay.stop();
          });
          this.el.addEventListener("mouseleave", () => {
            this.autoplay.start();
          });
        },
      },
    });
    swiper2.autoplay.start();
  },
};
</script>

<style scoped>
ul li:before {
  content: "✓ ";
  padding-right: 30px;
  position: absolute;
  left: 0;
}
.swiper-pagination {
  text-align: left;
}
</style>
