<template>
    <div class="publications">
      <Header />
      <div class="mt-20 lg:pl-24 lg:pr-24">
        <section>
        <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-12 lg:mb-12 sm:mb-8">
          <div class="font-semibold text-center lg:mb-8 sm:mb-4">
            <h2 class="sm:text-4xl mb-6 text-3xl sm:mb-4">Publications</h2>
          </div>
          <div class="grid grid-cols-1 gap-4 lg:grid-cols-1 lg:gap-16">
            <p class="">
                Welcome to the Milestones Consultancy Publications Hub, where knowledge meets innovation. 
                Our commitment to thought leadership and continuous learning is reflected in our diverse range of publications. 
                These resources are crafted to empower businesses, professionals, and enthusiasts with insights into the latest trends, 
                best practices, and industry advancements.
                <br>
                <p class="">
                In our publications, we delve into a variety of topics, offering strategic insights, practical advice, and in-depth analyses. 
                Whether you're seeking inspiration for organizational transformation, or staying updated on emerging technologies, 
                our publications cover a spectrum of subjects to cater to your interests.
                </p>
            </p>
          </div>
          <div class="border-gray-200 border-2 rounded-md mt-2 px-10 py-16">
            <h2 class="text-center mb-8 text-lg font-bold">Latest Publications</h2>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">A case for subsidies in developing countries like Kenya</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a 
                        :href="`${publicPath}A case for subsidies in developing countries like Kenya.pdf`" 
                        download="A case for subsidies in developing countries like Kenya">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="fmd:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Ageism as a threat to democracy</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Ageism as a threat to democracy.pdf`" download="Ageism as a threat to democracy">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Attracting and Retaining Investments in the Counties</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Attracting and Retaining Investments in the Counties.pdf`" download="Attracting and Retaining Investments in the Counties">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Bad Samaritans</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Bad Samaritans.pdf`" download="Bad Samaritans">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Beware of unsustainable economic models of growth</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Beware of unsustainable economic models of growth.pdf`" download="Beware of unsustainable economic models of growth">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Budget cuts bad for long term economic growth</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Budget cuts bad for long term economic growth.pdf`" download="Budget cuts bad for long term economic growth">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Bulding a Thundering Team</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Bulding a Thundering Team.pdf`" download="Bulding a Thundering Team">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Communication is to strategic planning what salt is to soup</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Communication is to strategic planning what salt is to soup.pdf`" download="Communication is to strategic planning what salt is to soup">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Development as Industry and Technology</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Development as Industry and Technology.pdf`" download="Development as Industry and Technology">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Devolution is the Ultimate Rural Transformation Economic Model</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Devolution is the Ultimate Rural Transformation Economic Model.pdf`" download="Devolution is the Ultimate Rural Transformation Economic Model">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Devolution is Working</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Devolution is Working.pdf`" download="Devolution is Working">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">GMO and Other Food Security Strategies</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}GMO and Other Food Security Strategies.pdf`" download="GMO and Other Food Security Strategies">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">How Public Participation will Rejig Devolution</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}How Public Participation will Rejig Devolution.pdf`" download="How Public Participation will Rejig Devolution">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">How Roosevelt Balanced Between Labor and Business</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}How Roosevelt Balanced Between Labor and Business.pdf`" download="How Roosevelt Balanced Between Labor and Business">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">How to make transitions less chaotic</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}How to make transitions less chaotic.pdf`" download="How to make transitions less chaotic">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Impacts of COVID19 on Kenya's Education Sector</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Impacts of COVID19 on Kenya's Education Sector.pdf`" download="Impacts of COVID19 on Kenya's Education Sector">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Inclusive political institutions as engines for economic prosperity</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Inclusive political institutions as engines for economic prosperity.pdf`" download="Inclusive political institutions as engines for economic prosperity">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Leadership Lessons from The Seals</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Leadership Lessons from The Seals.pdf`" download="Leadership Lessons from The Seals">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Leaders must have an ideology</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Leaders must have an ideology.pdf`" download="Leaders must have an ideology">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Lessons from the campaigns on Strategy</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Lessons from the campaigns on Strategy.pdf`" download="Lessons from the campaigns on Strategy">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Lessons from the Political Campaigns</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Lessons from the Political Campaigns.pdf`" download="Lessons from the Political Campaigns">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Negative Effects of Sudden Subsidy Withdrawal</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Negative Effects of Sudden Subsidy Withdrawal.pdf`" download="Negative Effects of Sudden Subsidy Withdrawal">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">The Challenge & Potential of Teams</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}The Challenge & Potential of Teams.pdf`" download="The Challenge & Potential of Teams">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">The Most Important Title is Citizen</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}The Most Important Title is Citizen.pdf`" download="The Most Important Title is Citizen">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">The Peoples Manifesto must Embrace Inclusive Institutions</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}The Peoples Manifesto must Embrace Inclusive Institutions.pdf`" download="The Peoples Manifesto must Embrace Inclusive Institutions">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">The Preconditions for an Effective Team</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}The Preconditions for an Effective Team.pdf`" download="The Preconditions for an Effective Team">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">The Reading Habits of Nelson Rolihlahla Mandela</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}The Reading Habits of Nelson Rolihlahla Mandela.pdf`" download="The Reading Habits of Nelson Rolihlahla Mandela">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">The Reading Habits of Raila Amollo Odinga</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}The Reading Habits of Raila Amollo Odinga.pdf`" download="The Reading Habits of Raila Amollo Odinga">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Why your strategy needs a strategy</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Why your strategy needs a strategy.pdf`" download="Why your strategy needs a strategy">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Why you should hire attitude over experience</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Why you should hire attitude over experience.pdf`" download="Why you should hire attitude over experience">
                        Download</a>
                    </button>
                </div>
            </div>
            <div class="border border-slate-500 mb-2"></div>
            <div class="md:flex lg:flex">
                <div class="w-full lg:w-3/4 md:w-3/4">
                    <ul>
                        <li class="relative mb-3 pl-6">Without the Team, Leaders are Nothing</li>
                    </ul>
                </div>
                <div class="w-full lg:w-1/4 md:w-1/4">
                    <button class="inline-flex items-center rounded border border-burgundy bg-burgundy px-4 lg:px-8 md:px-8 py-2 mb-3 text-white hover:bg-transparent hover:text-burgundy focus:outline-none focus:ring active:text-burgundy">
                        <a :href="`${publicPath}Without the Team, Leaders are Nothing.pdf`" download="Without the Team, Leaders are Nothing">
                        Download</a>
                    </button>
                </div>
            </div> 
            <div class="border border-slate-500 mb-2"></div>    
          </div>
          </div>
        </section>
      </div>
    </div>

</template>

<script>
import Header from "@/components/Header2.vue";


export default{
    components: {Header},
    data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  
}
</script>

<style scoped>
ul li:before{
    content: "✓ ";
    padding-right: 30px;
    position: absolute;
    left: 0;
}
</style>